import React, { useEffect } from 'react';


export default function GridBg() {

    useEffect(() => {

    }, []);



    return (
        <>
            <div id='grid-bg'>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
                <div className='grid-bg-col'></div>
            </div>
        </>
    );
};