import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Parallax } from 'react-scroll-parallax';
import fixComputer from '../assets/fix-computer.webp';
import logoYec from '../assets/yec.svg';
import TEDxConegliano from '../assets/TEDxConegliano.jpg';
import logoTEDxConegliano from '../assets/tedxconegliano-logo.png';
import crossfit from '../assets/crossfit.png';


export default function Home() {

    return (
        <>
            <Helmet>
                <title>Welcome in - Ceks.it</title>
                <meta name="description" content="" />
            </Helmet>

            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start'>
                    <Col md={1}></Col>
                    <Col md={6}>
                        <Parallax speed={-4}>
                            <h1>
                                Hi, I'm Marco <br />
                                I'm a Dev!
                            </h1>
                            <p className='lead'>
                                I'm passionate about web development.
                                <br />
                                I'm a Developer I don't fix computer.
                            </p>
                        </Parallax>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={3}>
                        <Image src={fixComputer} width={'100%'} />
                    </Col>
                </Row>
            </Container>

            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5'>
                    <Col md={6}></Col>
                    <Col md={4}>
                        <Parallax speed={-6}>
                            <h1>I'm a Dreamer</h1>
                            <p className='lead'>
                                In the sense that I love sleeping, so my probability of dreaming is higher than average.
                            </p>
                        </Parallax>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>

            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5'>
                    <Col md={1}></Col>
                    <Col md={6}>
                        <Parallax speed={3}>
                            <h1>I'm a CTO</h1>
                            <p className='lead'>
                                I'm CTO and Co-Founder of GRUPPO YEC.
                                <br/>
                                Please come to visit us!
                            </p>
                            <div className='mt-5 mb-5'>
                                <a href="https://gruppoyec.com" target="_blank">
                                    <span className='button-52 m-auto'>
                                        <Image src={logoYec} width={'120'}/>
                                    </span>
                                </a>
                            </div>
                        </Parallax>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={4}></Col>
                </Row>
            </Container>

            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5'>
                    <Col md={5}>
                        <Image src={TEDxConegliano} width={'100%'} />
                    </Col>
                    <Col md={1}></Col>
                    <Col md={4}>
                        <Parallax speed={-6}>
                            <h1>I'm a Ted-er</h1>
                            <p className='lead'>
                                I don't even know if the word 'Teder' exists.
                                <br/>
                                I certainly love the format and I am part of the organization of <u>TEDxConegliano</u>.
                            </p>
                            <div className='mt-5 mb-5'>
                                <a href="https://tedxconegliano.it" target="_blank">
                                    <span className='button-52 m-auto'>
                                        <Image src={logoTEDxConegliano} width={'120'}/>
                                    </span>
                                </a>
                            </div>
                        </Parallax>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>

            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5'>
                    <Col md={1}></Col>
                    <Col md={4}>
                        <Parallax speed={-4}>
                            <h1>I'm a CrossFitter</h1>
                            <p className='lead'>
                                I have been following CrossFit with passion since 2019.
                                <br/>
                                Occasionally, I practice it too.
                            </p>
                        </Parallax>
                    </Col>
                    <Col md={1}></Col>
                    <Col md={4}>
                        <Image src={crossfit} width={'100%'} />
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>

        </>
    )

};