import React, { useState, useEffect } from 'react';
import './css/style.css';
import './css/header.css';
import './css/footer.css';
import './css/blog.css';
import './css/custom.css';
import './config/bootstrap.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'


/** pages */
import Home from './pages/index';
import NotFound from './pages/not_found';
import About from './pages/about';
import Contact from './pages/contact';
import Blog from './pages/blog/index';
import BlogSearch from './pages/blog/search/index';
import Entry from './pages/entry/index';
import CookiePolicy from './pages/cookie_policy';


import HeaderBlock from './components/HeaderBlock';
import FooterBlock from './components/FooterBlock';
import GridBg from './components/GridBg';


import { Helmet } from "react-helmet";
import { settings } from './config/settings';
import { ParallaxProvider } from 'react-scroll-parallax';



function App() {

    library.add(fas)


    useEffect(() => {
        async function init() {

        }

        init()
    }, []);



    return (
        <ParallaxProvider>
            <Router basename={'/'}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{settings.title}</title>
                    <meta name="description" content={settings.description} />
                </Helmet>

                <div id="app-container">
                    <GridBg />
                    <HeaderBlock />
                    <main>
                        <Routes>
                            <Route exact path='/' element={<Home />} /> {/* default page */}
                            <Route path='/about' element={<About />} />
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/cookie_policy' element={<CookiePolicy />} />
                            <Route path="/blog/:p" element={<Blog />} />
                            <Route path="/search/:p" element={<BlogSearch />} />
                            <Route path="/entry/:k" element={<Entry />} />
                            <Route path="*" element={<NotFound />} /> {/* 404 page */}
                        </Routes>
                    </main>
                    <FooterBlock />
                </div>
            </Router>
        </ParallaxProvider>
    );


}

export default App;