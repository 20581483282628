import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';


export default function HeaderBlock() {

    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
        //var element = document.getElementById("app-container");
        //element.setAttribute("data-page", location.pathname);
    }, [location]);


    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);



    return (
        <>
            <div id='header'>
                <Container fluid className='pt-4 pb-4 text-white overflow-hidden' id="header-container">
                    <Row>
                        <Col xs={12} md={4}>
                            <h1 style={{ fontSize: '12vh' }}>ceks.it</h1>
                            <p className='lead'>Il futuro è adesso. Non svegliatemi ho sonno.</p>
                        </Col>
                        <Col xs={12} md={4}>

                        </Col>
                        <Col xs={12} md={4}>
                            <Navbar id="header-navbar" className='justify-content-end'>
                                <Nav>
                                    <Nav.Link to="/" as={Link} className={location.pathname === '/' ? 'active' : ''}>
                                        Homepage
                                    </Nav.Link>
                                    <Nav.Link to="/about" as={Link} className={location.pathname === '/about' ? 'active' : ''}>
                                        About
                                    </Nav.Link>
                                    {/*
                                    <Nav.Link to="/blog/1" as={Link} className={location.pathname.includes('blog') ? 'active' : ''}>
                                        Blog
                                    </Nav.Link>
                                    */}
                                    <Nav.Link to="/contact" as={Link} className={location.pathname === '/contact' ? 'active' : ''}>
                                        Contact
                                    </Nav.Link>
                                </Nav>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};