import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Parallax } from 'react-scroll-parallax';
import goodAfternoon from '../assets/good-afernoon.webp';
import noInternet from '../assets/no-internet.webp';


export default function About() {

    return (
        <>
            <Helmet>
                <title>About Me</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5'>
                    <Col md={2}></Col>
                    <Col md={4}>
                        <h1>Me, myself and I</h1>
                        <p className='lead'>Hi, I'm Marco,</p>
                        <Image src={goodAfternoon} width={'100%'} />
                    </Col>
                </Row>
                <Row className='text-start mt-5 mb-5'>
                    <Col md={8}></Col>
                    <Col md={3}>
                        <Parallax speed={-4}>
                            <h3><u>I'M A WEB DESIGNER AND DEVELOPER</u></h3>
                            <p>I enjoy creating tailor-made websites or web applications.</p>
                            <p>I speak React, Next.js, PHP and many other languages.</p>
                        </Parallax>
                    </Col>
                </Row>
                <Row className='text-start mt-5'>
                    <Col md={3}></Col>
                    <Col md={3}>
                        <Parallax speed={1}>
                            <h3><u>I LOVE SHARING IDEAS</u></h3>
                            <p><i>The miracle is this: The more we share the more we have.</i></p>
                        </Parallax>
                    </Col>
                </Row>
                <Row className='text-start mt-5'>
                    <Col md={3}></Col>
                    <Col md={3}>
                        <Parallax speed={-2}>
                            <Image src={noInternet} width={'100%'} />
                        </Parallax>
                    </Col>
                </Row>
            </Container>



            <Container fluid={'md'} className='mt-5 mb-5 pt-5 pb-5 text-center'>
                <p className='small' style={{color:'#aaa'}}>
                    Questo blog non rappresenta una testata giornalistica in quanto viene aggiornato senza alcuna periodicità o regolarità voluta.
                    Non può pertanto considerarsi un prodotto editoriale ai sensi della legge n° 62 del 7.03.2001.
                    Alcuni testi o immagini inseriti in questo blog sono tratti da internet e, pertanto, considerati di pubblico dominio; 
                    qualora la loro pubblicazione violasse eventuali diritti d’autore, vogliate comunicarlo via email, saranno immediatamente rimossi.
                </p>
            </Container>
        </> 
    );
    
};