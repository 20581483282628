import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ApiManager } from '../../core/api-manager';


export default function FooterBlock() {


    return (
        <Container fluid className='p-4 text-white' id="footer">
            <Container fluid='md' className='mt-5 mb-5 text-center'>
                <p className='small'>Powered by Marco with Love & React</p>
                <p className='small' style={{color:'#ccc'}}>If you really want to read my cookie policy, you can <Link to='/cookie_policy'>click here</Link>.</p>
            </Container>
        </Container>
    );
};