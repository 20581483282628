import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Image } from 'react-bootstrap';
import mars from '../assets/mars.webp';


export default function Contact() {
    
    return (
        <>
            <Helmet>
                <title>Contact Me!</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start mt-5 mb-5'>
                    <Col md={1}></Col>
                    <Col md={5}>
                        <h1>Getting in touch is easy!</h1>
                        <p className='lead'>
                            You can find me on Mars.<br />
                            51.1° S, 31.8° W - 95 km across.
                        </p>
                        <p className='lead'>
                            If you don't feel comfortable, you can find me at this email address during the night.<br/>
                            <u>hello@ceks.it</u>
                        </p>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                        <Image src={mars} width={'100%'} />
                    </Col>
                </Row>
            </Container>
        </>
    );

};