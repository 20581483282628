import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Parallax } from 'react-scroll-parallax';
import notfound from '../assets/404.png';


export default function NotFound() {
    
    return (
        <>
            <Helmet>
                <title>404 - Page Not Found - Ceks.it</title>
                <meta name="description" content="" />
            </Helmet>


            <Container fluid className='mt-5 mb-5 pt-5 pb-5 text-white'>
                <Row className='text-start'>
                    <Col md={1}></Col>
                    <Col md={1}>
                        <p className='small' style={{ color: '#ccc' }}>ERROR 404</p>
                    </Col>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Parallax speed={-8}>
                            <h1 className='mt-5'>Page not found</h1>
                            <p className='lead'>The page you are looking for cannot be found.</p>

                            <div className='mt-5 mb-5'>
                                <Link to="/">
                                    <span className='button-52 m-auto'>
                                        Back to home
                                    </span>
                                </Link>
                            </div>
                        </Parallax>
                    </Col>
                    <Col md={1}></Col>
                </Row>

                <Row className='text-start mt-5'>
                    <Col md={1}></Col>
                    <Col md={4} className='mt-5'>
                        <Parallax speed={-2}>
                            <p style={{ color: '#ccc' }}>An Andy Warhol style painting of a french bulldog astronaut eating a disconnected electrical cable.</p>
                            <Image src={notfound} width={'100%'} />
                        </Parallax>
                    </Col>
                    <Col md={7}></Col>
                </Row>
            </Container>
        </>
    );
    
};